<template>
<div>
    <b-row>
        <!-- <b-col md="6">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-chart-bar"></i> <span class="h5"> Estadísticas </span>
                </CCardHeader>
                <CCardBody>
                    <span class="h6 text-muted">ESTADÍSTICAS DEL SISTEMA PARA LOS CLIENTES
                    </span>
                    <hr>
                    <b-row class="justify-content-center text-center">
                        <b-col cols="12" class=" my-2 icon-custom">
                            <i style="inline-size: auto" class="fas fa-briefcase fa-3x ico"> </i>
                            <br>
                            <span class="lg-numero">{{listaAuditorias.length}}</span>
                            <br>
                            <span class="text-muted h6">Auditorias</span>
                        </b-col>
                    </b-row>
                </CCardBody>
            </CCard>
        </b-col> -->
        <b-col md="6">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-filter"></i><span class="h5"> Filtrar por cliente </span>
                </CCardHeader>
                <CCardBody>
                    <b-row>
                        <!-- <b-col cols="12" class="my-2 ">
                            <b-button block size="md" variant="custom" @click="modalSeleccionarCliente = true">
                                <i class="fas fa-plus-circle fa-3x pb-1" style="inline-size: auto"></i>
                                <br>Seleccionar Cliente por Nombre
                            </b-button>
                        </b-col> -->
                        <b-col md="12">
                            <validation-provider name="cliente" rules="required" v-slot="{errors}">
                                <b-form-group label="Seleccionar cliente:" class="mb-2">
                                    <v-select @input="seleccionarCliente()" :reduce="listaClientes => listaClientes.idSubCliente" label="razonSocial" placeholder="Seleccione una opción" :class="{'style-valid-select': filterBusqueda.idSubCliente  , 'style-invalid-select is-invalid': !!errors.length}" v-model="filterBusqueda.idSubCliente" :options="listaClientes">
                                        <span slot="no-options" class="text-muted">Busqueda no encontrada</span>
                                    </v-select>
                                    <div class="invalid-feedback">{{ errors[0]}}</div>
                                </b-form-group>
                            </validation-provider>
                        </b-col>

                        <b-col md="12">
                            <b-form-group label="Número de ruc:" class="mb-2">
                                <b-form-input :disabled="true" placeholder="Seleccione un cliente" v-model="filterBusqueda.numeroRuc"></b-form-input>
                            </b-form-group>
                        </b-col>

                        <b-col md="12">
                            <validation-provider name="codigo" rules="required" v-slot="{errors}">
                                <b-form-group label="Numero de cotizacion:" class="mb-2">
                                    <v-select :reduce="listaCotizaciones => listaCotizaciones.idCotizacionCertificacion" label="codigo" placeholder="Seleccione una opción" :class="{'style-valid-select': filterBusqueda.idCotizacionCertificacion  , 'style-invalid-select is-invalid': !!errors.length}" v-model="filterBusqueda.idCotizacionCertificacion" :options="listaCotizaciones">
                                        <span slot="no-options" class="text-muted">Busqueda no encontrada</span>
                                    </v-select>
                                    <div class="invalid-feedback">{{ errors[0]}}</div>
                                </b-form-group>
                            </validation-provider>
                        </b-col>

                        <!-- <b-col md="6">
                            <validation-provider name="razón social" :rules="{ required: true }" v-slot="validationContext">
                                <b-form-group label="Razón social:" class="mb-2">
                                    <b-form-input :disabled="true" :state="getValidationState(validationContext)" placeholder="Ingrese la razón social" v-model="datosNuevoCliente.razonSocial"></b-form-input>
                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        </b-col> -->
                    </b-row>

                </CCardBody>
            </CCard>
        </b-col>
        <b-col md="6">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-cog"></i><span class="h5"> Gestión de auditoria </span>
                </CCardHeader>
                <CCardBody>
                    <b-row class="text-center justify-content-center">
                        <b-col md="6" class=" my-2 icon-custom vr">
                            <i style="inline-size: auto" class="fas fa-briefcase fa-3x ico"> </i>
                            <br>
                            <span class="lg-numero">{{listaAuditorias.length}}</span>
                            <br>
                            <span class="text-muted h6">Auditorias</span>
                        </b-col>
                        <b-col md="6" class="my-2 ">
                            <b-button :disabled="!listaCotizaciones.length>0" block size="md" variant="custom" :to="{name: 'Nueva Auditoria'}">
                                <i class="fas fa-plus-circle fa-3x pb-1" style="inline-size: auto"></i>
                                <br>Nuevo auditoria
                            </b-button>
                        </b-col>
                    </b-row>
                </CCardBody>
            </CCard>
        </b-col>

        <b-col md="12">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-list fa-md"></i> <span class="h5"> Clientes Seleccionados</span>
                </CCardHeader>

                <CCardBody>
                    <b-row>
                        <b-col md="2">
                            <b-form-group label="Registros por página" class="text-muted">
                                <b-form-select size="xl" v-model="porPagina" :options="paginaOpciones"></b-form-select>
                            </b-form-group>
                        </b-col>
                        <b-col md="7">

                        </b-col>
                        <b-col md="3">
                            <b-form-group label="Busqueda:" class="text-muted">
                                <b-input-group size="xl">
                                    <b-input-group-prepend is-text>
                                        <i class="fas fa-search  my-0 mx-0"></i>
                                    </b-input-group-prepend>
                                    <b-form-input type="search" v-model="filter" id="filterInput" placeholder="Buscar..."></b-form-input>
                                </b-input-group>
                            </b-form-group>
                        </b-col>
                        <b-col md="12">
                            <b-table bordered hover show-empty mediun responsive outlined :items="listaAuditorias" :fields="campoAuditorias" :current-page="paginaActual" :per-page="porPagina" :filter="filter" :filter-included-fields="filterOn" @filtered="onFiltered" empty-text="Aún no hay datos en esta sección." empty-filtered-text="No hay datos que coincidan con tu busqueda.">
                                <template v-slot:cell(index)="data">{{ data.index + 1 }}</template>
                                <template #table-colgroup="campoAuditorias">
                                    <col v-for="field in campoAuditorias.fields" :key="field.key" :style="`width: ${field.width}`">
                                </template>

                                <template v-slot:cell(auditorLider)="data">
                                    <span> {{data.value | truncate(27)}}</span>
                                </template>
                                <template v-slot:cell(normas)="data">
                                    {{
                                       String('* '+listaNormas.filter((x) => data.value.includes(x.idNorma)).map(y => ` ${y.descripcion}, `).join(' * '))
                                    }}
                                </template>
                                <template v-slot:cell(fechaInicio)="data">
                                    {{convertDateTime(data.item.fechaInicio).substr(0,10)}}
                                </template>
                                <template v-slot:cell(fechaFin)="data">
                                    {{convertDateTime(data.item.fechaFin).substr(0,10)}}
                                </template>
                                <template v-slot:cell(fechaCreacion)="data">
                                    {{convertDateTime(data.item.fechaCreacion)}}
                                </template>
                                <template v-slot:cell(opciones)="param">
                                    <b-button size="sm" class="mr-1 mb-1" variant="dark" v-c-tooltip.hover.click="'Ver detalles'" :to="{name: 'Nueva Auditoria', params: {id: param.item.idAuditoria}}">
                                        <span class="btn-inner--icon">
                                            <i class="fas fa-search  my-0 mx-0"></i>
                                        </span>
                                    </b-button>

                                    <b-button size="sm" class="mr-1 mb-1" v-c-tooltip.hover.click="'Eliminar'" @click="eliminarCliente(param)" variant="danger">
                                        <span class="btn-inner--icon">
                                            <i class="fas fa-trash-alt  my-0 mx-0"></i>
                                        </span>
                                    </b-button>
                                </template>
                            </b-table>
                        </b-col>
                    </b-row>

                </CCardBody>
                <CCardFooter>
                    <b-pagination size="sm" class="my-0 float-right" v-model="paginaActual" :total-rows="filasTotales" :per-page="porPagina" first-number last-number></b-pagination>
                </CCardFooter>
            </CCard>
        </b-col>
    </b-row>
</div>
</template>

<script>
import axios from "axios";
import CONSTANTES from "@/Constantes.js";

export default {

    data() {
        return {

            listaCotizaciones: [],
            listaAuditorias: [],
            listaClientes: [],

            campoAuditorias: [{
                    key: "index",
                    label: "N°",
                    class: "text-center",
                    width: "5%"
                },
                {
                    key: "normas",
                    label: "Normas",
                    class: "text-center",
                    width: "45%"
                },
                // {
                //     key: "nombreComercial",
                //     label: "Nombre Comercial",
                //     class: "text-center"
                // },
                // {
                //     key: "auditorLider",
                //     label: "Auditor Lider",
                //     class: "text-center"
                // },
                // {
                //     key: "expertoTecnico",
                //     label: "Experto Tecnico",
                //     class: "text-center",
                // },
                {
                    key: "fechaInicio",
                    label: "Fecha de inicio",
                    class: "text-center",
                    width: "15%"
                },
                {
                    key: "fechaFin",
                    label: "Fecha fin",
                    class: "text-center",
                    width: "15%"
                },
                {
                    key: "fechaCreacion",
                    label: "Fecha de creación",
                    class: "text-center",
                    width: "15%"
                },
                {
                    label: "",
                    key: "opciones",
                    class: "text-center",
                    width: "5%"
                },
            ],

            filasTotales: 0,
            paginaActual: 1,
            porPagina: 10,
            paginaOpciones: [10, 25, 50, 100, {
                value: 9999999,
                text: "Mostrar todos"
            }],
            filter: null,
            filterOn: [],
            listaNormas: [{
                    idNorma: 1,
                    descripcion: 'ISO 9001: 2015'
                },
                {
                    idNorma: 2,
                    descripcion: 'ISO 14001: 2015'
                },
                {
                    idNorma: 3,
                    descripcion: 'ISO 45001: 2018'
                },
                {
                    idNorma: 4,
                    descripcion: 'ISO 37001: 2016'
                },
                {
                    idNorma: 5,
                    descripcion: 'ISO 27001: 2013'
                },
                {
                    idNorma: 6,
                    descripcion: 'ISO 20000: 2011'
                },
            ],

            filterBusqueda: {
                idSubCliente: null,
                numeroRuc: '',
                idCotizacionCertificacion: ''
            }

            // datosNuevoCliente: {
            //     idCliente: '',
            //     numeroRuc: '',
            //     razonSocial: '',
            //     estado: 2,
            // },
            // disabled: false
        }
    },

    methods: {

        getValidationState({
            dirty,
            validated,
            valid = null
        }) {
            return dirty || validated ? valid : null;
        },
        convertDateTime(datetime) {
            return moment(datetime).format('YYYY-MM-DD HH:mm:ss');
        },

        onFiltered(itemsFiltrados) {
            this.filasTotales = itemsFiltrados.length
            this.paginaActual = 1
        },
        guardarLocalFilter() {
            localStorage.setItem('filterBusqueda', JSON.stringify(this.filterBusqueda))
        },

        listarAuditorias(idCotizacionCertificacion) {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(CONSTANTES.URL_RUTA_SERVICIOS + "cliente/auditorias/listar-auditorias", {
                    params: {
                        idCotizacionCertificacion,
                    }
                })
                .then(function (response) {
                    me.listaAuditorias = response.data;
                    me.filasTotales = me.listaAuditorias.length;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },
        eliminarCliente(param) {
            let me = this
            me.$swal.fire({
                title: '¿Estas seguro de eliminar la auditoría?',
                text: "¡No podrás revertir esto!",
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#d33',
                confirmButtonText: 'Eliminar',
            }).then((result) => {
                if (result.isConfirmed) {
                    axios
                        .post(CONSTANTES.URL_RUTA_SERVICIOS + "cliente/auditorias/eliminar-auditoria", {
                            idAuditoria: param.item.idAuditoria
                        }, {
                            headers: {
                                Authorization: `Bearer ${localStorage.token}`,
                            }
                        })
                        .then(function (response) {
                            let color = response.data.resultado == 1 ? "success" : "error";
                            me.swat(color, response.data.mensaje)
                            me.listarAuditorias(me.filterBusqueda.idCotizacionCertificacion)
                        })
                        .catch(function (error) {
                            me.swat('error', 'Algo salió mal!')
                        });
                }
            })
        },
        listarCotizacionIQC(idSubCliente) {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(CONSTANTES.URL_RUTA_SERVICIOS + "cliente/auditorias/listar-cotizacion-iqc", {
                    params: {
                        idSubCliente: idSubCliente
                    }
                })
                .then(function (response) {
                    me.listaCotizaciones = response.data;
                    if (me.listaCotizaciones.length > 0) {
                        me.filterBusqueda.idCotizacionCertificacion = me.listaCotizaciones[0].idCotizacionCertificacion
                        me.listarAuditorias(me.listaCotizaciones[0].idCotizacionCertificacion);
                    }
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },

        listarClientes() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(CONSTANTES.URL_RUTA_SERVICIOS + "cliente/comercial/listar-clientes-iqc", {
                    params: {
                        estado: 2,
                        idCliente: me.$store.state.user.uidClient
                    }
                })
                .then(function (response) {
                    me.listaClientes = response.data;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },

        seleccionarCliente() {
            //clean variables
            this.listaAuditorias = [];
            this.filterBusqueda.idCotizacionCertificacion = null

            let clienteSeleccionado = this.listaClientes.filter(x => x.idSubCliente == this.filterBusqueda.idSubCliente)

            this.filterBusqueda.numeroRuc = clienteSeleccionado[0].numeroRuc
            let idSubCliente = clienteSeleccionado[0].idSubCliente
            this.listarCotizacionIQC(idSubCliente)
        },

        swat(icon, title) {
            this.$swal.mixin({
                toast: true,
                showConfirmButton: false,
                position: 'bottom-right',
                timer: 3500,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', this.$swal.stopTimer)
                    toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                }
            }).fire({
                icon: icon,
                title: title
            });
        },

    },
    watch: {
        'filterBusqueda.idSubCliente': function (val) {
            if (val) {
                this.guardarLocalFilter();
            }
        },
        'filterBusqueda.idCotizacionCertificacion': function (val) {
            if (val) {
                this.guardarLocalFilter();
                this.listarAuditorias(this.filterBusqueda.idCotizacionCertificacion)
            }
        },

    },
    mounted() {
        if (this.$store.state.user) {
            this.listarClientes();
            if (localStorage.getItem('filterBusqueda')) {
                this.filterBusqueda = JSON.parse(localStorage.getItem('filterBusqueda'))
                if (this.filterBusqueda.idCotizacionCertificacion) {
                    this.listarCotizacionIQC(this.filterBusqueda.idSubCliente)
                }
            }
        }
    }
}
</script>
